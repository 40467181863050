'use client'

import React, { useEffect } from 'react'
import { Button, Form, Card } from 'antd'

import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import { url } from 'inspector'
import styles from '@/app/auth/login/page.module.css'
import Image from 'next/image'
import loginBg from '../../../../public/images/login-background/login.png'
import microsoftIcon from '../../../../public/images/login-background/microsoft-logo.png'
type FieldType = {
  email?: string
  password?: string
}

const SignInPage: React.FC = () => {
  const router = useRouter()
  const onFinish = async (values: any) => {
    //console.log('Success:', values)
    await signIn('azure-ad', { callbackUrl: '/home' })
  }
  const { status } = useSession()

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    if (status === 'authenticated') {
      router.push('/home')
    }
  }, [status])

  return (
    <div className={styles.loginContainer} style={{ backgroundImage: `url(${loginBg.src})` }}>
      {status === 'unauthenticated' && (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ marginTop: '22vh', marginRight: '7vw', borderRadius: '0.5%' }}>
          <Card style={{ width: '30vw', height: '63vh' }} className={styles.loginCard}>
            <div className={styles.imageLogo}>
              <Image
                alt={'logo'}
                src={'/images/logo.svg'}
                style={{
                  width: 'auto',
                  height: 'auto',
                }}
                priority={true}
                loading={'eager'}
                height="140"
                width="140"
                className={'logo-image'}
              />
            </div>
            {/* <Form.Item className="align-middle"> */}
            <div style={{ marginTop: '20%' }}>
              <label className={styles.fontLabel}>Sign in using microsoft credentials</label>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                style={{
                  backgroundColor: '#FE5000',
                  borderColor: '#FE5000',
                  marginTop: '4%',
                }}>
                <div className={styles.signInClass}>
                  <span style={{ marginRight: '3%' }}>Sign in </span>
                  <Image
                    src={microsoftIcon}
                    alt="Image Alt"
                    width={12}
                    height={12}
                    style={{ height: 'min-content', marginTop: '1.5%' }}
                  />
                </div>
              </Button>
            </div>
            <div
              style={{
                color: 'white',
                fontFamily: "'Poppins', sans-serif",
                fontWeight: '300',
                fontSize: '12px',
                marginTop: '30%',
              }}>
              &#169;Copyrights Tredence 2023
            </div>
          </Card>
        </Form>
      )}
    </div>
  )
}

export default SignInPage
